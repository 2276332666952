import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import InputButtonku from "./components/button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
function App() {
    const [password, setPassword] = useState("");
    const [truePassword, setTruePassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tanggal, setTanggal] = useState("");
    const [type, setType] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [metode, setMetode] = useState("");
    const [jumlah, setJumlah] = useState("");
    const [desc, setDesc] = useState("");
    const signin = () => {
        if (password === "@Kucing8") {
            setTruePassword(true);
        } else {
            setTruePassword(false);
        }
    };
    const signout = () => {
        setTruePassword(false);
    };
    const store = () => {
        setIsLoading(true);
        var moment = require("moment");
        console.log(tanggal);
        var formatteddate = moment(tanggal, "YYYY/MM/DD");
        console.log(formatteddate);

        var day = formatteddate.format("D");
        var month = formatteddate.format("M"); // Menggunakan format "M" untuk menghilangkan nol di depan bulan
        var year = formatteddate.format("YYYY");
        console.log(month + "/" + day + "/" + year);
        fetch("https://sheetdb.io/api/v1/ae5vmer9djhtv", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: [
                    {
                        No: "INCREMENT",
                        HARI: "",
                        TANGGAL: month + "/" + day + "/" + year,
                        TYPE: type,
                        KETERANGAN: keterangan,
                        METODE: metode,
                        JUMLAH: type == "income" ? jumlah : "-" + jumlah,
                        DESC: desc,
                    },
                ],
            }),
        })
            .then((response) => {
                setTanggal("");
                setDesc("");
                setJumlah("");
                setKeterangan("");
                setMetode("");
                setType("");
                setIsLoading(false);
                response.json();
            })
            .then((data) => {
                setTanggal("");
                setDesc("");
                setJumlah("");
                setKeterangan("");
                setMetode("");
                setType("");
                setIsLoading(false);
                console.log(data);
            });
    };
    return (
        <div className="App">
            <header className="App-header">
                {/* <Button
                    onClick={() => {
                        store();
                    }}
                >
                    asds
                </Button> */}
                {/* <FormTextku></FormTextku> */}
                {truePassword ? (
                    <div>
                        <div className=".row">
                            <h7>Tanggal</h7>
                            <Form.Control
                                type="date"
                                value={tanggal}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => setTanggal(e.target.value)}
                            />
                        </div>
                        <div className=".row">
                            <h7>TYPE</h7>
                            {/* <Form.Control
                                type="text"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => setType(e.target.value)}
                            /> */}

                            <Form.Select
                                aria-label="Default select example"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option>Open this select menu</option>
                                <option value="income">Income</option>
                                <option value="outcome">Outcome</option>
                            </Form.Select>
                        </div>
                        <div className=".row">
                            <h7>KETERANGAN</h7>
                            <Form.Control
                                type="text"
                                value={keterangan}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => setKeterangan(e.target.value)}
                            />
                        </div>
                        <div className=".row">
                            <h7>METODE</h7>
                            {/* <Form.Control
                                type="text"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => setMetode(e.target.value)}
                            /> */}

                            <Form.Select
                                aria-label="Default select example"
                                value={metode}
                                onChange={(e) => setMetode(e.target.value)}
                            >
                                <option>Open this select menu</option>
                                <option value="TUNAI">TUNAI</option>
                                <option value="BCA">BCA</option>
                                <option value="MANDIRI">MANDIRI</option>
                                <option value="BUKOPIN">BUKOPIN</option>
                                <option value="BJBS">BJBS</option>
                                <option value="JENIUS">JENIUS</option>
                                <option value="DANA">DANA</option>
                                <option value="OVO">OVO</option>
                                <option value="LINKAJA">LINKAJA</option>
                                <option value="SHOPEE">SHOPEE</option>
                                <option value="GOPAY">GOPAY</option>
                            </Form.Select>
                        </div>
                        <div className=".row">
                            <h7>JUMLAH</h7>
                            <Form.Control
                                value={jumlah}
                                type="number"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => setJumlah(e.target.value)}
                            />
                        </div>
                        <div className=".row">
                            <h7>DESC</h7>
                            <Form.Control
                                type="text"
                                value={desc}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => setDesc(e.target.value)}
                            />
                        </div>
                        <InputButtonku
                            display={isLoading ? "Loading..." : "Submit"}
                            disabled={isLoading}
                            className=""
                            clicked={store}
                        />
                        <InputButtonku display="Log Out" className="" clicked={signout} />
                    </div>
                ) : (
                    <div>
                        <div className=".row">
                            <Form.Control
                                type="password"
                                id="inputPassword5"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <InputButtonku display="Sign In" clicked={signin} />
                    </div>
                )}
            </header>
        </div>
    );
}

export default App;
