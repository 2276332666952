import Button from "react-bootstrap/Button";

function InputButtonku(props) {
    return (
        <>
            <Button
                variant="primary mt-2"
                onClick={() => {
                    props.clicked();
                }}
                className="mx-2"
                disable={props.display === "Submit" ? false : true}
            >
                {props.display}
            </Button>
        </>
    );
}

export default InputButtonku;
